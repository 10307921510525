import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/request'
// import { detectZoom } from '@/utils/detectZoom.js';
// const m = detectZoom();
// document.body.style.zoom = 100 / Number(m);


Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import jquery from "jquery";
Vue.prototype.$ = jquery;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
