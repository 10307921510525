<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
	html,body,div,span,applet,object,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
		margin:0;
		padding:0;
		border:0;
		font-size:100%;
		font:inherit;
		font-weight:normal;
		vertical-align:baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
		display:block;
	}
	ol,ul,li {
		list-style:none;
	}
	blockquote,q {
		quotes:none;
	}
	blockquote:before,blockquote:after,q:before,q:after {
		content:'';
		content:none;
	}
	table {
		border-collapse:collapse;
		border-spacing:0;
	}
	th,td {
		vertical-align:middle;
	}
	/* custom */
	a {
		outline:none;
		color:#16418a;
		// text-decoration:none;
		-webkit-backface-visibility:hidden;
	}
	a:focus {
		outline:none;
	}
	input:focus,select:focus,textarea:focus {
		outline:-webkit-focus-ring-color auto 0;
	}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
</style>
<script>
	export default{
		data(){
			return{
				
			}
		},
		mounted(){
		  // if (this._isMobile()) {
		  //   // alert("手机端");
		  //   this.$router.replace('/mobile');
		  // } else {
		  //   // alert("pc端");
		  //   this.$router.replace('/');
		  // }
		},
		methods:{
		    _isMobile() {
			 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			 return flag;
			}
		}
	}
</script>
