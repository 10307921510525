import Vue from 'vue'
import axios from 'axios'
axios.defaults.baseURL = 'https://www.silvtong.cn:9443' 
// axios.defaults.baseURL = 'http://172.16.4.187:8888' 

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response.data;
  }, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  });



Vue.prototype.$http = axios;