<template>
	<div>
		<!-- banner -->
		<div>
			<div class="banner">
				<!-- logo -->
				<div class="banner_details">
					<div class="banner_logo">
						<img src="../assets/logo_web.png" alt="">
					</div>
					<!-- 导航栏 -->
					<div class="nav_right">
						<div v-for="(item,index) in nav_list" class="nav_list" @click="get_index(item,index)" :class="check_index==index?'tab_act':'tab_old'">
							<div style="">
								{{item.title}}
							</div>
							<div class="line" v-show="check_index==index">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于我们 -->
		<!-- 标题 -->
		<div class="banner_mi">
			<img src="../../src/assets/banner_midd.png" alt="">
		</div>
		<!-- <div class="about_us">
			<div class="about_title">
				关于我们
			</div>
			<div class="lineus">
				<img src="../../src/static/images/us.png" alt="">
			</div>
			<div class="line_bottom">
				<img src="../../src/static/images/shanghai.png" alt="">
			</div>
			<div class="content">
				<div>
					上海思律通信息科技有限公司，成立于2021年，位于上海市黄浦区宝鼎大厦15楼，毗邻田子坊、日月光等景点和商圈。
				</div>
				<div>
					公司以“通用、有效、便捷”为基本理念，目前旗下拥有两款产品——思律App与奇点数学App。
				</div>
				<div>
					上海思律通信息科技有限公司，以社会需求、国家发展为出发点，立足于发挥信息工具的优越性，为广大用户提供强有力的辅助手段，并通过思想的内化使用户充分激发出自身的潜力，未来将使全社会的生活、工作、学习面貌焕然一新。
				</div>
			</div>
		</div> -->
		<!-- 主要产品 -->
		<div class="main">
			<div class="main_details">
				<div>
					主要产品
				</div>
				<div class="main_line">
					<img src="../../src/assets/chanpin.png" alt="">
				</div>
			</div>
			<div class="main_list">
				<!-- 思律 -->
				<div class="main_details2">
					<div @click="target_silv()">
						<img src="../assets/new/silv.png" alt="">
					</div>
					<div class="text">
						<div>
							思律App颠覆了传统思维书籍与思维培训的形式，能够做到以问题为导向；同时又有别于常见的问答平台，强调通用方法，因而能够被真正掌握；其特有的工具属性能使用户在交互过程中完成思律App将思考的书面化，同时减少在向人请教过程中的抵触心理。
						</div>
						<div>
							可以说，只要是想提高自己的思考水平而不得其法的人，无论处于什么阶段，思律App都能提供有力的帮助。思律App在未来的普及，将大幅提高国民素质，为中国从人口大国向人才强国的转型贡献力量。
						</div>
					</div>
				</div>
				<!-- 奇点数学 -->
				<div class="main_list">
					<div class="main_details2">
						<div @click="target_qidian()">
							<img src="../assets/new/qidian.png" alt="">
						</div>
						<div class="text">
							<div>
								奇点数学App运用思律中的部分原理，结合数学学科的主要特点，为思考在数学学习这一垂直领域的落地提供了一个范例。
							</div>
							<div>
								顺应当下“双减”政策发布后为教育减负的时代背景，通过强调通用方法，强调自主性，让学生们从繁杂的所谓解题技巧中挣脱出来，感悟数学之道，无招胜有招，从根本上解决减负与学习之间的矛盾，在无损中国的国际竞争力的同时，为全社会减负。
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 理念 -->
		<div class="idea">
			<img style="" src="../assets/new/bannerbottom.png" alt="">
		</div>
		<!-- 页脚 -->
		<div class="footer_sl">
			<!-- 图片 -->
			<div class="logo_bottom">
				<img  style="" src="../assets/logo_web.png" alt="">
			</div>
			<div class="footer_main">
				<div class="gzwm"> 
					<a href="">关注我们/</a>
					<img src="../assets/dianshi.png" alt="..">
					<img src="../assets/wechat.png" alt="">
				</div>
				<div class="footerMiddle">
					<div class="footMiddle_details">
						<a>思律APP下载/</a>
						<a href="https://apps.apple.com/cn/app/%E6%80%9D%E5%BE%8B/id6444752391"><img style="" src="../assets/apple.png" alt=""></a>
						<a href="https://www.silvtong.cn:9443/Thinking/profile/upload/sl.apk"><img style="" src="../assets/anzhuo.png" alt=""></a>
					</div>
					<div class="footMiddle_details" style="		">
						<a>奇点数学APP下载/</a>
						<a href="https://apps.apple.com/cn/app/%E5%A5%87%E7%82%B9%E6%95%B0%E5%AD%A6/id1630303264"><img style="" src="../assets/apple.png" alt=""></a>
						<a href="https://silvtong.cn/ruoyi-admin/profile/upload/qdsx.apk"><img style="" src="../assets/anzhuo.png" alt=""></a>
					</div>
				</div>
				<div class="footMiddle_details2">
					
					<img style="" src="../assets/email.png" alt="">
					<a href="">email:hr@silvtong.cn</a>
				</div>
			</div>
				<!-- 公关备案 -->
			<div class="reference">
				<div>
					上海思律通信息科技有限公司出品
				</div>
				<div style="" class="text_right">
					<a style="color: #fff;" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">ICP备2021034852号-1</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeView',
		data() {
			return {
				downloadNumber: null,
				nav_list: [{
						title: '首页'
					},
					{
						title: '思律APP'
					},
					{
						title: '奇点数学APP'
					}
				],
				check_index:0,//当前选中的导航索引
			}
		},
		components: {

		},
		mounted() {
			this.get_downLoad()
		},
		methods: {
			// 获取下载次数
			get_downLoad() {
				this.$http.get('/system/global/downloadNumber').then(res => {
						console.log(res)
						if (res.code == 200) {
							this.downloadNumber = res.data.downloadNumber
						}
					})
					.catch(err => {
						console.log(err)
					});
			},
			get_index(item,index){
				console.log(item,index)
				this.check_index = index
				if (index == 0) {
					this.$router.push('./')
				} else if (index == 2) {
					this.$router.push('./mobile')
				}else if(index==1){
					this.$router.push('./silv')
				}
			},
			target_silv(){
				this.$router.push('./silv')
			},
			target_qidian(){
				this.$router.push('./mobile')
			},
		}
	}
</script>
<style lang="scss" scoped>
		a{
			color: #FFFFFF;
			text-decoration: none;
		}
		
	html {
		font-size: 16px;
	}
	// 兼容手机端 动态窗口 class
	.tab_act{
		font-size: 24px;
		margin-top: 33px;
	}
	.tab_old{
		font-size: 18px;
		margin-top: 17px;
	}
	.text_right{
		margin-left: 40px;
	}
	.footMiddle_details2 {
		img{
			margin-top: 10px;
			width:17px ;height: 13px;
		}
	}
	.banner_details {
		border-bottom: 1px solid rgba(216, 216, 216, 0.3);
		padding-top: 32px;
		padding: 32px 206px 0 206px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.banner {
		height: 758px;
		width: 100%;
		background: url('../assets/home_banner.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;

		img {
			width: 402px;
			height: 50px;
		}
	}

	.nav_right {
		display: flex;
		align-items: center;
		color: #ffffff;
	}

	.line {
		margin-top: 30px;
		height: 2px;
		width:  70%;
		background: #FFFFFF;
		border-radius: 384px 384px 384px 384px;
	}

	.about_us {
		height: 758px;
		width: 100%;
		padding: 144px 0 0;
		background-image: url('../assets/main_banner.png');
		background-size: 80% 80%;
		background-repeat: no-repeat;
		background-position: right center;
	}

	.lineus {
		img {
			height: 5px;
			width: 92px;
			margin-top: 12px;
			padding-left: 230px;
		}
	}

	.about_title {
		font-size:40px;
		color: #3D3D3D;
		padding-left: 220px;
	}

	.content {
		width: 40%;
		color: #3d3d3d;
		font-size: 24px;
		margin-top: 32px;
		line-height: 45px;
		padding-left: 230px;
	}
	.banner_mi{
		img{
			width: 100%;
			height: 860px;
		}
	}

	.nav_list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 80px;
	}

	.line_bottom {
		padding-left: 230px;
		margin-top: 40px;

		img {
			height: 30px;
			width: 468px;
		}
	}

	.main_line {
		img {
			height: 5px;
			width: 92px;
			margin-top: 12px;
		}
	}

	.main_details {
		margin-left: 239px;
		color: #FFFFFF;
		font-size: 40px;
	}

	.main {
		height: 1443px;
		width: 100%;
		padding: 144px 0 0;
		background-image: url('../assets/yas.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: right center;
	}

	.main_details2 {
		padding: 28px;
		background-color: #fff;
		display: flex;
		align-items: center;
		margin: 84px 156px 0 156px;
		border-radius: 40px;

		img {
			height: 464px;
			width: 410px;
		}
	}

	.text {
		margin-left: 90px;
		font-size: 28px;
		color: #3D3D3D;
		line-height: 60px;
	}

	.idea {
		img {
			width: 100%;
			height: 520px;
		}
	}
	.logo_bottom{
		img{
			height: 50px;width: 402px;
		}
			padding: 27px 0 20px 0;
			padding-left: 10%;
			border-bottom: 1px solid rgba(216, 216, 216, 0.3);
		}
	.footer_sl{
		background-color: #001F45;
	}
	.footMiddle_details{
		margin-top: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		a>img{
			width:27px ;height: 27px;
		}
		img{
			width:34px ;height: 26px;
		}
	}
	.footer_main{
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		font-size: 24px;
		padding: 27px 0;
	}
	.footerMiddle{
	}
	.gzwm{
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			height: 33px;
			width: 33px;
		}
	}
	.reference{
		display: flex;
		color: #fff;
		font-size:14px ;
		padding:0  0 2% 56%;
	}
</style>
